import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import Swiper from 'react-id-swiper';
import ContainerDimensions from 'react-container-dimensions';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Yunmee.module.scss';
import StoryItem from '../../misc/StoryItem';

const cx = classNames.bind(styles);

const illustrations = [
  { image: require('assets/images/story/brand/illustration-1.jpg') },
  { image: require('assets/images/story/brand/illustration-2.jpg') },
  { image: require('assets/images/story/brand/illustration-3.jpg') },
  { image: require('assets/images/story/brand/illustration-4.jpg') },
];

const params = {
  effect: 'fade',
  slidesPerView: 1,
  spaceBetween: 8,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
};

export default class Yunmee extends Component {

  slidePrev = () => {
    this.yunmeeSwiper.slidePrev();
  }

  slideNext = () => {
    this.yunmeeSwiper.slideNext();
  }

  render() {
    return (
      <ScreenClassRender render={(screenClass) => {
        const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

        return (
          <Container className={cx('artisanSection')}>
            <Row align="center">
              {/* Swiper column */}
              {!isMobile && (
                <Col lg={5} offset={{ lg: 1 }} className={cx('column')}>
                  <ContainerDimensions>
                    {({ width }) => {
                      const height = width;

                      return (
                        <div>
                          <div className={cx('swiperWrapper')}>
                            <Swiper {...params} ref={node => { if (node) this.yunmeeSwiper = node.swiper }}>
                              {illustrations.map((illustration, i) => {
                                return (
                                  <div key={String(i)} className={cx('image')} style={{ width, height, backgroundImage: `url(${illustration.image})` }} />
                                );
                              })}
                            </Swiper>
                            <div className={cx('navigation')}>
                              <div className={cx('button')} onClick={this.slidePrev}><Ionicon icon="ios-arrow-back" fontSize="17px" color="white" /></div>
                              <div className={cx('button')} onClick={this.slideNext}><Ionicon icon="ios-arrow-forward" fontSize="17px" color="white" /></div>
                            </div>
                          </div>
                          <div className={cx('skillTitle')}>Illustration</div>
                        </div>
                      );
                    }}
                  </ContainerDimensions>
                </Col>
              )}
              {isMobile && (
                <Col xs={12} className={cx('column')}>
                  {image}
                </Col>
              )}

              <Col xs={12} lg={4.5} offset={{ lg: .5 }} className={cx('column')}>
                <StoryItem
                  title={<span>Yunmee<br/>Kyung</span>}
                  subtitle="감성적인 스토리를 담고 있는 일러스트"
                >
                  아티제의 일러스트는 Ezra Jack Keats New Illustrator’s Award 수상, 평화롭고 따스한 감성을 담는 경연미 작가의 손끝에서 만들어 졌습니다. 각각의 일러스트에는 저마다의 감성적인 스토리가 담겨, 고객님들과 소통합니다, 위트 있고 따뜻한 일러스트들을 아티제 매장에서 만나보세요.
                </StoryItem>
                
                {!isMobile && image}
              </Col>

              {/* Swiper column */}
              {isMobile && (
                <Col xs={12} className={cx('column')}>
                  <ContainerDimensions>
                    {({ width }) => {
                      const height = width;
                      
                      return (
                        <div>
                          <div className={cx('swiperWrapper')}>
                            <Swiper {...params} ref={node => { if (node) this.yunmeeSwiper = node.swiper }}>
                              {illustrations.map((illustration, i) => {
                                return (
                                  <div key={String(i)} className={cx('image')} style={{ width, height, backgroundImage: `url(${illustration.image})` }} />
                                );
                              })}
                            </Swiper>
                            <div className={cx('navigation')}>
                              <div className={cx('button')} onClick={this.slidePrev}><Ionicon icon="ios-arrow-back" fontSize="17px" color="white" /></div>
                              <div className={cx('button')} onClick={this.slideNext}><Ionicon icon="ios-arrow-forward" fontSize="17px" color="white" /></div>
                            </div>
                          </div>
                          <div className={cx('skillTitle')}>Illustration</div>
                        </div>
                      );
                    }}
                  </ContainerDimensions>
                </Col>
              )}
            </Row>
          </Container>
        );
      }} />
    );
  }
}

const image = (
  <img
    src={require('assets/images/story/brand/clip-3.jpg')}
    width="100%"
    alt="아티제 일러스트"
  />
);