import React from 'react';
import { Container, Row, Col, Visible, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import Paragraph from '../../misc/Paragraph';
import Yunmee from './Yunmee';

const cx = classNames.bind(styles);

const BrandStory = () => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);
    const noBr = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx(['contentWrapper', { center: isMobile }])}>
        <Container className={cx('conceptSection')}>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }}>
              <div className={cx(['headline', { noBr }])}>a piece of art,<br/>artisée</div>
            </Col>
            <Col xs={12} lg={5}>
              <div className={cx(['slogan', { noBr }])}>everything at artisée is an art work,<br/>made by our artisans</div>
              <Paragraph>아티제는 자유로움과 편안함, 삶의 여유를 추구하는 이들에게 감성과 휴식을 선사하는 라이프 스타일 카페입니다. 스토리가 있는 일러스트레이션과 커뮤널 테이블, 숙련된 바리스타가 내린 향긋한 coffee, 시각과 미각 모두를 만족시키는 cake & pastry까지… 아티제에서 만나는 모든 것은 artisan(장인)이 만든 하나의 작품입니다.</Paragraph>
            </Col>
          </Row>
        </Container>
        
        <Container className={cx('meaningSection')}>
          <Row align="end">
            <Col xs={12} lg={4.5} offset={{ lg: 1 }} className={cx('column')}>
              <Paragraph>아티제(artisée)는 프랑스어로 장인을 의미하는 ‘artisan’과 여성적인 이미지를 강조하는 ‘ée’를 결합한 이름으로 장인정신을 바탕으로 부드럽고 섬세한 맛을 제공하겠다는 의지를 담고 있는 공간입니다.</Paragraph>
            </Col>
            <Col xs={12} lg={5} offset={{ lg: .5 }} className={cx('column')}>
              <img
                src={require('assets/images/story/brand/clip-1.jpg')}
                width="100%"
                alt="artisée"
              />
            </Col>
          </Row>
          <Visible lg xl>
            <div className={cx('circleStamp')}></div>
          </Visible>
        </Container>
        
        <Yunmee />
      </div>
    );
  }} />
);

export default BrandStory;