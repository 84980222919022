import React from 'react';

import BrandStory from 'components/Story/BrandStory';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '브랜드 스토리', link: '/the-story/brand-story/' },
  { name: '커피 스토리', link: '/the-story/coffee-story/' },
];

const BrandStoryPage = () => (
  <div>
    <Jumbotron
      engTitle="Brand Story"
      korTitle="브랜드 스토리"
      image={require('../../assets/images/banner/story.jpg')}
      content={
        <span>
          아티제는 자유로움과 편안함, 삶의 여유를 추구하는 이들에게
          <br />
          감성과 휴식을 선사하는 라이프 스타일 카페입니다.
          <br />
          <br />
        </span>
      }
    />
    <TabBar index={0} data={tabBarMenus} />
    <BrandStory />
  </div>
);

export default BrandStoryPage;
